import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useCookies } from 'vue3-cookies';

export const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'default',
		component: () => import('../layouts/DefaultLayout.vue'),
		children: [
			{
				path: '/',
				name: 'home',
				component: () => import('../views/employees/EmployeeITList.vue'),
			},
			{
				path: '/employees/tasks',
				name: 'employees-tasks',
				component: () => import('@/views/employeeTasks/EmployeesTasks.vue'),
			},
			{
				path: '/employee/history:id?',
				name: 'employee-history',
				component: () => import('@/views/employee/EmployeeInfoHistory.vue')
			},
			{
				path: '/employees/logistic',
				name: 'employees-logistic',
				component: () =>import('../views/employees/EmployeeLogisticList.vue'),
			},
			{
				path: '/employees/archive',
				name: 'employees-archive',
				component: () => import('../views/employees/EmployeesHistory.vue'),
			},
			{
				path: `/userinfo/:id`,
				name: 'userinfo',
				component: () => import('../views/UserInfo.vue'),
				meta: { requiresAuth: true }
			},
			{
				path: '/useredit/:id?',
				name: 'useredit',
				component: () => import('../views/employee/EmployeeUpdate.vue'),
				meta: { requiresAuth: true }
			},
			{
				path: '/usercreate',
				name: 'usercreate',
				component: () => import('../views/employee/EmployeeCreate.vue'),
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: '/',
		name: 'auth',
		redirect: '/login',
		component: () => import('../layouts/AuthLayout.vue'),
		children: [
			{
				path: '/login',
				name: 'login',
				component: () => import('../views/auth/Login.vue'),
				meta: { requiresAuth: false }
			}
		]
	},
	{
		path: '/:notFound(.*)',
		name: 'notfound',
		component: () => import('../views/NotFound.vue'),
		meta: { requiresAuth: false }
	}
];

// const router = createRouter({
// 	history: createWebHistory(),
// 	routes
// });

// const { cookies } = useCookies();

// router.beforeEach((to, _, next) => {
// 	const token = cookies.get('token');
// 	const loggedIn = token !== undefined && token !== null && token !== "";

// 	if (to.matched.some((record) => record.meta.requiresAuth)) {
// 		if (loggedIn) {
// 			next();
// 		} else {
// 			next({ name: 'login' });
// 		}
// 	} else if (loggedIn && to.path === '/login') {
// 		next({ name: 'home' });
// 	} else {
// 		next();
// 	}
// });

// export default router;
const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, _, next) => {
	console.log("test user name");
	const { cookies } = useCookies();
	const token = cookies.get('token');

	if (!token) {
		if (to.name !== 'login' && to.name !== 'register') {
			console.log("test login");
			return next({ name: 'login' });
		}
	}
	else {
		if (to.name === 'login') {
			return next('/');
		}
	}

	return next();
});

export default router;
