import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import '@/assets/styles/main.css';
import { createPinia } from 'pinia';
import router from './router';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import App from './App.vue';

const app = createApp(App);
app.use(Antd);
app.use(createPinia());
app.use(router);
app.use(ToastPlugin);
app.mount('#app');